<template>
  <div>
    <div>
      <el-input
        placeholder="请输入内容"
        class="input-with-select"
      >
        <el-select slot="prepend" placeholder="请选择">
          <el-option label="疾病" value="1"></el-option>
          <el-option label="基因" value="2"></el-option>
          <el-option label="线数" value="3"></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <el-table :data="cliniclist" style="width: 100%">
      <el-table-column prop="dengji" label="登记号" width="180">
      </el-table-column>
      <el-table-column prop="gene" label="基因" width="180"> </el-table-column>
      <el-table-column prop="jcname" label="项目名称"> </el-table-column>
      <el-table-column prop="score" label="积分" width="180"> </el-table-column>
      <el-table-column prop="period" label="线数" width="180">
      </el-table-column>
      <el-table-column prop="indication" label="适应症"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getdata} from "../api";
export default {
  data() {
    return {
      cliniclist: [],
    };
  },

  mounted() {
    getdata("/getclinic").then((data) => {
      console.log("getclinic", data.data);
      this.cliniclist = data.data;
    });

  },
};
</script>

<style>
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
