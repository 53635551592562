<template>
  <div>
    <el-container>
      <el-aside width="auto">
        <Aside></Aside>
      </el-aside>
      <el-container>
        <el-header>
          <Header></Header>
        </el-header>
        <el-main>
          <Tags></Tags>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Aside from "../components/Aside.vue";
import Header from "../components/Header.vue";
import Tags from "../components/Tags.vue";
export default {
  data() {
    return {
    };
  },
  components: {
    Aside,
    Header,
    Tags
  },
};
</script>

<style lang="less" scoped>
.el-header {
  padding: 0;
}
</style>
