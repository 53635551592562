import http from "../utils/request";
import bt from "../utils/bt";
//请求数据接口
export const getdata = (url) => {
    return http.get(url)
}

export const postdata = (url,data) => {
    return http.post(url,data)
}


export const getbtdata = (url) => {
    return bt.get(url)
}



