<template>
  <div>
    <download-excel
      class="export-excel-wrapper"
      :data="artlist"
      :fields="artlistfield"
      name="artlist.xlsx"
    >
      <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
      <el-button type="primary" size="small">导出EXCEL</el-button>
    </download-excel>
    <el-button type="text" @click="dialogFormVisible = true"
      >打开嵌套表单的 Dialog</el-button
    >
    <el-table :data="artlist">
      <el-table-column prop="title" label="标题" width="150"></el-table-column>
      <el-table-column
        prop="subtitle"
        label="副标题"
        width="200"
      ></el-table-column>
      <el-table-column prop="content" label="选项"></el-table-column>
    </el-table>

    <el-dialog title="数据导出" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules">
        <el-form-item label="导出格式" :label-width="formLabelWidth">
          <el-select v-model="form.region" placeholder="请选择导出格式">
            <el-option label="CSV" value="shanghai"></el-option>
            <el-option label="PDF" value="beijing"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleExprotData">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { exportCsv } from "@/utils/utils";
import { getdata } from "../api";
export default {
  data() {
    return {
      // json_fields: {
      //   "Complete name": "name",    //常规字段
      //   Telephone: "phone.mobile", //支持嵌套属性
      //   "Telephone 2": {
      //     field: "phone.landline",
      //               //自定义回调函数
      //     callback: value => {
      //       return `Landline Phone - ${value}`;
      //     }
      //   }
      // },
      // json_data: [
      //   {
      //     name: "Tony Peña",
      //     city: "New York",
      //     country: "United States",
      //     birthdate: "1978-03-15",
      //     phone: {
      //       mobile: "1-541-754-3010",
      //       landline: "(541) 754-3010",
      //     },
      //   },
      //   {
      //     name: "Thessaloniki",
      //     city: "Athens",
      //     country: "Greece",
      //     birthdate: "1987-11-23",
      //     phone: {
      //       mobile: "+1 855 275 5071",
      //       landline: "(2741) 2621-244",
      //     },
      //   },
      // ],

      // json_meta: [
      //   [
      //     {
      //       " key ": " charset ",
      //       " value ": " utf- 8 ",
      //     },
      //   ],
      // ],

      gridDatafield: {
        title: "title", //常规字段
        subtitle: "subtitle", //支持嵌套属性
        content: {
          field: "content",
          //自定义回调函数
          // callback: (value) => {
          //   return `Landline Phone - ${value}`;
          // },
        },
      },

      gridData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      dialogFormVisible: false,
      dialogTableVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "120px",
      artlistfield: {
        title: "title", //常规字段
        subtitle: "subtitle", //支持嵌套属性
        content: {
          field: "content",
          //自定义回调函数
          // callback: (value) => {
          //   var str = value.split(',');
          //   return str;
          // },

          callback: (value) => {
            return `[${value},]`;
          },
        },
      },
      artlist: [],
      rules: {},
    };
  },

  mounted() {
    this.getuserinfo();
    // getdata("/getusers").then((data) => {
    //   console.log("getusers", data.data);
    //   this.userslist = data.data;
    // });
  },

  methods: {
    getuserinfo() {
      getdata("/getartcode").then((data) => {
        console.log("getusers", data.data);
        this.artlist = data.data;
      });
    },
    handleExprotData() {
      //表头
      // let allColumns = [];
      // this.gridData.forEach((obj) => {
      //   if (obj.label !== undefined && obj.prop !== undefined) {
      //     allColumns.push({ title: obj.label, key: obj.prop });
      //   }
      // });
      // this.dialogFormVisible = false;
      // //this.checkboxList是勾选的需要导出的数据
      // console.log(
      //   JSON.parse(JSON.stringify("allColumns -----------", allColumns))
      // );
      // console.log(
      //   JSON.parse(
      //     JSON.stringify("this.checkboxList ----------------", this.gridData)
      //   )
      // );
      // exportCsv(allColumns, this.gridData, "CP信息");
      //生成文件
      // let arr = ["日期,名称,地址"]; //第一行，列名。csv文件会默认根据逗号分割成列
      // let content = [];
      // let data = this.gridData; //需要导出的列表
      // for (let i = 0; i < data.length; i++) {
      //   let temp = [];
      //   temp.push(data[i].date); //每一列
      //   temp.push(data[i].name);
      //   temp.push(data[i].address);
      //   content.push(temp.join(",")); //添加每一行的内容
      // }
      // console.log(content);
      // let uri =
      //   "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(content.join(""));
      // let downloadLink = document.createElement("a");
      // downloadLink.href = uri;
      // downloadLink.download = "unit.csv";
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);
      //exportCsv(arr, content, "CP信息");
      // let content = "data:text/csv;charset=utf-8,\ufeff" + arr.join("\n"); //文件格式+文件内容，\n换行
      // var link = document.createElement("a");
      // link.setAttribute("href", content);
      // link.setAttribute("download", "unit.csv");
      // link.click();
    },
  },
};
</script>
