<template>
  <div>
    <div>
      <el-input
        placeholder="请输入内容"
        class="input-with-select"
      >
        <el-select slot="prepend" placeholder="请选择">
          <el-option label="CSCO" ></el-option>
          <el-option label="NCCN" ></el-option>
          <el-option label="CACA" ></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <el-table :data="cancerlist" style="width: 100%">
      <el-table-column prop="fileclassify" label="类别" width="180">
      </el-table-column>
      <el-table-column prop="filename" label="文件名" width="180">
      </el-table-column>
      <el-table-column prop="fileversion" label="版别"> </el-table-column>
      <el-table-column prop="content" label="备注">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { postdata } from "../api";
export default {
  data() {
    return {
      cancerlist: [],
    };
  },

  mounted() {
    // getdata("/getcancerdata").then((data) => {
    //   console.log("getcancerdata", data.data);
    //   this.cancerlist = data.data;
    // });

    postdata("/getcancerdata",{flag:'getall'}).then((data) => {
      console.log("getcancerdata", data.data);
      this.cancerlist = data.data;
    });
  },
};
</script>

<style>
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
