<template>
  <div>
    <el-table :data="genelist" style="width: 100%">
      <el-table-column prop="_id" label="序号" width="180">
      </el-table-column>
      <el-table-column prop="name" label="名称" width="180"> </el-table-column>
      <el-table-column prop="istop" label="是否置顶"> </el-table-column>
      <el-table-column prop="isshow" label="是否显示" width="180"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getbtdata} from "../api";
export default {
  data() {
    return {
      genelist: [],
    };
  },

  mounted() {
    getbtdata("/orders").then((res) => {
      console.log("orders", res.data.data);
      let item=res.data.data
      item.forEach(element => {
        console.log(element);
        if(element.istop){
          element.istop='是'
        }
        else{
          element.istop='否'
        }
        if(element.isshow){
          element.isshow='是'
        }
        else{
          element.isshow='否'
        }
      });
      this.genelist = item;
    });
  },
};
</script>